import { Box, Button, Stack, Typography } from "@mui/material"
import QuotaProgress from "components/DashboardSections/QuotaProgress"

const { REACT_APP_CAMERA_LINK } = process.env

const Dashboard = () => {

    const handleCameraClick = () => {
        window.open(REACT_APP_CAMERA_LINK, '_target')
    }

    return (
        <Box sx={{marginTop: 4}}>
            <Stack sx={{mb: 4}} direction="row" alignContent={'center'} justifyContent={'space-between'}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom >
                    Dashboard
                </Typography>

                <Button variant="outlined" onClick={handleCameraClick}>Cameras</Button>
            </Stack>

            <Stack flex={1} alignItems={'center'} justifyContent={'center'} spacing={2}>
                <QuotaProgress />
            </Stack>
		</Box>
    )
}

export default Dashboard