import { Divider, Stack, Typography } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { StyledPaper } from 'components/StyledPaper';
import { WarrantyData } from 'util/types/warranty-types';

const WarrantyDetails = ({ rowData }: { rowData: Row<WarrantyData> }) => {
    return (
        <StyledPaper elevation={6} sx={{ flex: 1, maxWidth: 720 }}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" divider={<Divider flexItem />} spacing={1}>
                <Typography variant={'h6'} sx={{ fontWeight: 'bold' }} align="center">
                    Claim Description
                </Typography>

                <Stack spacing={4} alignItems={'flex-start'} sx={{ padding: '14px 0', width: '100%' }}>
                    <Typography variant={'body2'} sx={{ whiteSpace: 'pre-wrap' }}>
                        {rowData.original.details}
                    </Typography>
                </Stack>
            </Stack>
        </StyledPaper>
    );
};

export default WarrantyDetails;
