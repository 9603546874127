import { LoadingButton } from "@mui/lab"
import { API } from "api"
import { useSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { API_ENDPOINTS } from "util/constants/api"
import { queryKeys } from "util/constants/queryKeys"
import { useState } from 'react'
import { Close } from "@mui/icons-material"
import { Button, DialogTitle, IconButton, DialogContent, Typography, DialogActions, Box, TextField } from '@mui/material'
import { StyledDialog } from "components/StyledDialog"
import { warrantyAction } from "util/helpers/apiHelpers"


const OrderNoModal = ({ rowId,  isEnquiry = false, isWarranty=false}) => {
    const [ isOpen, setOpen ] = useState(false)
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar() 
    
    const [ orderNoText, setOrderNoText ] = useState('')

    const mutation = useMutation(() => {
        if (isWarranty) {
            return warrantyAction({ isAccepted: true, orderNo: orderNoText, id: rowId })
        }

        const param = isEnquiry ? '?enquiry=true' : ''
        let apiURL = `${API_ENDPOINTS.ORDERS}/${(rowId)}${param}`

        return API.patch(apiURL, { orderNo: orderNoText})
    }, {
        onSuccess: ({ data }) => {
            const variant = data.success ? 'success' : 'error'
            
            enqueueSnackbar(data.message, { variant })
            
            if (data.success) {
                if (isEnquiry) {
                    queryClient.invalidateQueries(queryKeys.enquiryData)
                } else if (isWarranty) {
                    queryClient.invalidateQueries(queryKeys.warrantyData)
                } else {
                    queryClient.invalidateQueries('sales')
                }
            }

            setOpen(false)
        },
        onError: ({data}) => {
            enqueueSnackbar(data.message, { variant: 'error'})
        },
    })

    return (
        <div>
            <Button onClick={(e) => {
                e.stopPropagation()
                setOpen(true)
            }}>
                Add Order No.
            </Button>
            <StyledDialog onClick={(e) => e.stopPropagation()} open={isOpen} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex'}}>
                    Add Order No.
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Box sx={{pl: 1}}>
                        <TextField 
                            value={orderNoText} 
                            onChange={(event) => {
                                setOrderNoText(event.target.value)
                            }} 
                            label={'Order No.'}
                            type={'text'}
                            fullWidth
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="text" sx={{marginRight: 'auto'}} onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton loading={mutation.isLoading} disabled={!orderNoText} variant="contained" onClick={() => mutation.mutate()}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </StyledDialog>
        </div>
    )
}

export default OrderNoModal