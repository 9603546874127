import { LoadingButton } from "@mui/lab"
import { COLORS } from "util/constants/commons"

import { Close, Delete } from "@mui/icons-material"
import {styled} from '@mui/material/styles'
import { Button, DialogTitle, IconButton, DialogContent, Typography, DialogActions, Box, ImageList, ImageListItemBar, ImageListItem, useTheme, useMediaQuery, Backdrop, } from "@mui/material"

import { StyledDialog } from "components/StyledDialog"
import {useCallback, useMemo, useState} from 'react'
import ImagePreviewModal from "./ImagePreviewModal"
import { API } from "api"
import { API_ENDPOINTS } from "util/constants/api"
import { useSnackbar } from "notistack"
import { useImageList } from "hooks/lookups.hooks"
import { useQueryClient } from "react-query"
import { PermissionBarrier } from "components/Permissions"
import { ROLES } from "util/constants/permissions"
import useImageStore from 'store/images'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const ImageListModal = ({currPage, isLoading = false, handleToggle }) => {
    const queryClient = useQueryClient()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const selectedOrderNo = useImageStore((state) => state.selectedOrderNo)
    const isImageModalOpen = useImageStore((state) => state.isModalOpen)
    const isWarrantyImage = useImageStore((state) => state.isWarranty)
    const setModalToggle = useImageStore((state) => state.setModalToggle)
    const setSelectedOrderNo = useImageStore((state) => state.setSelectedOrderNo)

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [selectedImg, setSelectedImg] = useState({img: '', imageName: ''})
    const [isUploadLoading, setIsUploadLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    
    const { enqueueSnackbar } = useSnackbar()

    const { data, isLoading: isImgListLoading, isError } = useImageList(selectedOrderNo, { enabled: isImageModalOpen, isWarrantyImage })

    const handlePreview = (imgData) => {
        setSelectedImg(imgData)
        setIsPreviewOpen(true)
    }

    const processImgList = useMemo(() => {
        if (!isImgListLoading && isImageModalOpen) {
            const origin = window.location.origin
            console.log(data, 'owo')
            const combinedList = data.images.map(image => {
                return {img: `${origin}/api/images/${selectedOrderNo}/${image}`, imageName: image}
            })
            
            

            return combinedList
        }

        return []
    }, [isImgListLoading, data, selectedOrderNo, isImageModalOpen])

    const handleDelete = useCallback( async (item) => {
        setIsDeleteLoading(true)

        const { data } = await API.delete(`${API_ENDPOINTS.UPLOAD_IMG}/${selectedOrderNo}/${item.imageName}`)

        if (data.success) {
            enqueueSnackbar('Delete Success', {variant: 'success'})
            queryClient.invalidateQueries(['imageList', selectedOrderNo])

        } else {
            enqueueSnackbar('Failed to delete', {variant: 'error'})
        }

        setIsDeleteLoading(false)

    }, [selectedOrderNo, queryClient, enqueueSnackbar])

    const handleUpload =  useCallback( async (e) => {
        setIsUploadLoading(true)
        const imgList = Array.from(e.target.files)

        const formData = new FormData()
        
        imgList.forEach(file => {
            formData.append('files', file)
        })


        const { data } = await API.post(`${API_ENDPOINTS.UPLOAD_IMG}/${selectedOrderNo}`, formData , 
        { 
            headers: { 
                "Content-Type": "multipart/form-data"
                }
            }    
        )
        e.target.value = null;

        if (data.success) {
            enqueueSnackbar('Upload Success', {variant: 'success'})
            queryClient.invalidateQueries(['imageList', selectedOrderNo])

        } else {
            enqueueSnackbar('Failed to upload', {variant: 'error'})
        }

        setIsUploadLoading(false)
    }, [enqueueSnackbar, queryClient, selectedOrderNo])

    const handleClose = () => {
        setModalToggle(false)
        setSelectedOrderNo('')
    }

    return (
        <>
            <StyledDialog onClick={(e) => e.stopPropagation()} open={isImageModalOpen} onClose={handleClose} maxWidth='sm' fullWidth >
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex'}}>
                    Image List {isWarrantyImage ? '' : `- ${selectedOrderNo}`} 
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{background: COLORS.neutral_gray}}>
                    <Box sx={{pl: 1}}>
                        {(!isImgListLoading && !isError) && (
                            <ImageList sx={{ width: 'auto', height: 'auto' }} gap={12}>
                            {processImgList.map((item, index) => (
                                <ImageListItem key={`${item.img}${index}`}>
                                    <Button onClick={() => handlePreview(item)} sx={{flex: 1, width: '100%', height: '100%', p: 0, borderRadius: "8px",}} >
                                        <img
                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                            alt={item.imageName}
                                            style={{ maxWidth: '100%', maxHeight: '100%', minHeight: isMobile ? 120 : 248}}
                                            loading="lazy"
                                        />
                                    </Button>
                                    <ImageListItemBar
                                        title={item.imageName}
                                        sx={{background: 'rgba(0, 0, 0, 0.8)'}}
                                        actionIcon={
                                        <LoadingButton 
                                            sx={{ color: COLORS.danger, borderRadius: 100, minWidth: 44, mr: 2 }}
                                            size="small"
                                            variant="text"
                                            loading={isDeleteLoading}
                                            onClick={() => handleDelete(item)}
                                            aria-label={`delete ${item.imageName}`}
                                        >
                                            <span style={{display: 'flex', justifyContent: 'center'}}>
                                                <Delete />
                                            </span>
                                        </LoadingButton>
                                        }
                                    />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        )}

                        {(isError || processImgList.length === 0) && (
                            <Typography textAlign={'center'} pt={2} pb={4}>No images found.</Typography>
                        )}
                    </Box>  
                </DialogContent>
                        
                <DialogActions>
                    {(currPage === 'orderBook' || currPage === 'despatch' || currPage === 'history') && (
                        <PermissionBarrier scopes={[ROLES.admin, ROLES.sales, ROLES.factory]}>
                            <LoadingButton sx={{mr: 2}} component='label' variant="contained" loading={isUploadLoading} disabled={isUploadLoading}>
                                Upload
                                <VisuallyHiddenInput type="file" name="image" multiple onChange={handleUpload} />
                            </LoadingButton>
                        </PermissionBarrier>
                    )}
                    <Button variant="text" sx={{marginRight: 'auto'}} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </StyledDialog>

            <ImagePreviewModal imgData={selectedImg} onClose={() => setIsPreviewOpen(false)} isOpen={isPreviewOpen} />
        </>
    )
}

export default ImageListModal