import { Button, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ActionModal } from 'components/Modals';
import SkeletonLoader from 'components/SkeletonLoader';
import { TableSearch } from 'components/Tables/TableSearch';
import { useDespatch, useOrderbook } from 'hooks/pages.hooks';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrderData, RawOrder } from 'util/types/order-types';
import useAuthStore from 'store/auth';
import { ROLES } from 'util/constants/permissions';
import OTable2, { CustomColumnDef } from 'components/Tables/OTable2';
import { Error, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import useImageStore from 'store/images';
import ProgressBar from 'components/Loaders/ProgressBar';
import { usePermission } from 'hooks/permission.hooks';
import { format, isAfter, parse, parseISO } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import { FilterFn, Row } from '@tanstack/react-table';
import { Details } from 'components/Details';
import { TicketComments } from 'components/CommentsList/TicketComments';
import { RawDespatch } from 'util/types/despatch-types';

const DespatchTable = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [searchString, setSearchString] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');

    const { isLoading, error, data, isError, isFetching, refetch } = useDespatch({
        onSuccess: (data: any) => {
            enqueueSnackbar('Despatch fetched', { variant: 'success', key: 'despatchSnack' });
        },
        onError: (data: any) => {
            enqueueSnackbar(data.message, { variant: 'error', key: 'despatchSnack' });
        },
    });

    const authRole = useAuthStore((state) => state.authRole);
    const { roleSwitch } = usePermission();

    const isWholesaler = useMemo(() => authRole === ROLES.wholesaler, [authRole]);
    const setImageModalOpen = useImageStore((state) => state.setModalToggle);
    const setImageModalOrderNo = useImageStore((state) => state.setSelectedOrderNo);

    const processedRows = useMemo<RawDespatch[]>(() => {
        if (!isLoading && !error) {
            const rawData = data;

            return rawData.map((despatchData: RawDespatch) => {
                return {
                    orderNo: despatchData.orderNo,
                    client: despatchData.client,
                    clientNo: despatchData.clientNo ?? '',
                    jobDesc: despatchData.jobDesc,
                    paid: despatchData.paid,
                    items: despatchData.items,
                    dueDate: despatchData.dueDate,
                    itemsReady: despatchData.itemsReady,
                } as RawDespatch;
            });
        }

        return [];
    }, [data]);

    const columnDefs = useMemo<CustomColumnDef<RawDespatch>[]>(
        () => [
            {
                id: 'expander',
                header: () => null,
                cell: ({ row }) => {
                    return (
                        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
                            {row.getIsExpanded() ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    );
                },
                isIconButton: true,
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'orderNo',
                header: 'Ord#',
                cell: (info) => info.getValue(),
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'client',
                header: 'Customer',
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'clientNo',
                header: 'Client Ord#',
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'jobDesc',
                header: 'Description',

                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'paid',
                header: 'Paid',
                minWidth: 125,
                cell: (info) => (info.getValue() ? 'Paid Order' : 'Unpaid Order'),
            },
            {
                id: 'actions',
                header: 'Actions',
                enableSorting: false,
                enableGlobalFilter: false,
                minWidth: 150,
                cell: ({ row }) => {
                    const handleOpenImageModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();
                        const orderData = row.original;

                        setImageModalOpen(true);

                        setImageModalOrderNo(orderData.orderNo);
                    };

                    return (
                        <Button variant="text" onClick={handleOpenImageModal} sx={{ textTransform: 'capitalize' }}>
                            Show Images
                        </Button>
                    );
                },
            },
            {
                accessorKey: 'dueDate',
                header: roleSwitch({
                    [ROLES.wholesaler]: 'Target Date',
                    default: 'Deadline',
                }),
                minWidth: 175,
                cell: (info) => {
                    const dateInfo = info.getValue()
                        ? format(parse(info.getValue() as string, SERVER_FORMAT, new Date()), DISPLAY_FORMAT)
                        : '';

                    let isDateAfter = false;

                    if (dateInfo) {
                        isDateAfter = isAfter(new Date(), new Date(info.getValue() as string));
                    }

                    return dateInfo;
                },
                footer: (props) => props.column.id,
            },
        ],
        [processedRows]
    );

    const handleSearch = useCallback((text) => {
        setSearchString(text);
    }, []);

    const customTableFilter: FilterFn<OrderData> = useCallback(
        (row, columnId, filterValue, addMeta) => {
            let itemPass = true;

            if (!filterValue) return itemPass;

            const rowColValue: any = row.getValue(columnId);

            if (filterValue.text) {
                if (!rowColValue.toString().toLowerCase().includes(filterValue.text.toLowerCase())) {
                    itemPass = false;
                }
            }

            return itemPass;
        },
        [processedRows]
    );

    useEffect(() => {
        if (isFetching && !isLoading) {
            enqueueSnackbar('Refetching...', { variant: 'info', key: 'despatchSnack' });
        }
    }, [isFetching, isLoading, enqueueSnackbar]);

    const renderSubComponent = useCallback(
        ({ row }: { row: Row<RawDespatch> }) => {
            const originData = row.original;
            return (
                <Stack direction="row" sx={{ width: '100%', flexWarp: 'wrap' }} justifyContent={'center'}>
                    {/* @ts-ignore: Legacy component */}
                    {originData.items.map((item, index) => {
                        return <Details.DespatchItems key={index} itemDetails={item} />;
                    })}
                </Stack>
            );
        },
        [processedRows]
    );

    // Loading state component
    if (isLoading) {
        return <SkeletonLoader />;
    }

    return (
        <>
            <TableSearch onSearch={handleSearch} />

            <OTable2<RawDespatch>
                data={processedRows}
                columns={columnDefs}
                defaultAsc
                filterData={{
                    text: searchString,
                }}
                customFilter={customTableFilter}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            />

            {/*  @ts-ignore: Legacy component */}
            <ActionModal.ImageList currPage={'despatch'} />
        </>
    );
};

export default DespatchTable;
