import { Button, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ActionModal } from 'components/Modals';
import SkeletonLoader from 'components/SkeletonLoader';
import { TableSearch } from 'components/Tables/TableSearch';
import { useOrderbook } from 'hooks/pages.hooks';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrderData, RawOrder } from 'util/types/order-types';
import useAuthStore from 'store/auth';
import { ROLES } from 'util/constants/permissions';
import OTable2, { CustomColumnDef } from 'components/Tables/OTable2';
import { Error, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import useImageStore from 'store/images';
import ProgressBar from 'components/Loaders/ProgressBar';
import { usePermission } from 'hooks/permission.hooks';
import { format, isAfter, parse, parseISO } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import { FilterFn, Row } from '@tanstack/react-table';
import { Details } from 'components/Details';
import { TicketComments } from 'components/CommentsList/TicketComments';

const filterList = [
    {
        id: '',
        value: 'All',
    },
    {
        id: '2',
        value: 'Clevershade',
    },
    {
        id: '1',
        value: 'Revolvashade',
    },
    {
        id: '3',
        value: 'Blacksheep',
    },
    {
        id: '4',
        value: 'Trax',
    },
    {
        id: '5',
        value: 'Misc',
    },
];

const OrderbookTable = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [searchString, setSearchString] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');

    const { isLoading, isFetching, error, data, isError } = useOrderbook({
        onSuccess: (data: any) => {
            enqueueSnackbar('Orderbook fetched', { variant: 'success', key: 'orderSnack' });
        },
        onError: (data: any) => {
            enqueueSnackbar(data.message, { variant: 'error', key: 'orderSnack' });
        },
    });

    const authRole = useAuthStore((state) => state.authRole);
    const { roleSwitch } = usePermission();

    const isWholesaler = useMemo(() => authRole === ROLES.wholesaler, [authRole]);
    const setImageModalOpen = useImageStore((state) => state.setModalToggle);
    const setImageModalOrderNo = useImageStore((state) => state.setSelectedOrderNo);

    const processedRows = useMemo<OrderData[]>(() => {
        if (!isLoading && !error) {
            const rawData = data;

            return rawData.map((orderData: RawOrder) => {
                let orderName = orderData.cReference ?? orderData.customer;

                if (isWholesaler) {
                    orderName = orderData.customer;
                }

                return {
                    orderName,
                    orderNo: orderData.orderNo,
                    clientNo: orderData.clientNo,
                    jobDescription: orderData.jobDescription,
                    progressPercent: orderData.progressPercent,
                    dueDate: orderData.dueDate,
                    tickets: orderData.tickets,
                    divCode: orderData.divCode,
                } as OrderData;
            });
        }

        return [];
    }, [data]);

    const columnDefs = useMemo<CustomColumnDef<OrderData>[]>(
        () => [
            {
                id: 'expander',
                header: () => null,
                cell: ({ row }) => {
                    return (
                        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
                            {row.getIsExpanded() ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    );
                },
                isIconButton: true,
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'orderNo',
                header: 'Ord#',
                cell: (info) => info.getValue(),
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'orderName',
                header: 'Customer',
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'clientNo',
                header: 'Client Ord#',
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'jobDescription',
                header: 'Description',

                cell: (info) => info.getValue(),
            },
            {
                id: 'actions',
                header: 'Actions',
                enableSorting: false,
                enableGlobalFilter: false,
                minWidth: 150,
                cell: ({ row }) => {
                    const handleOpenImageModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();
                        const orderData = row.original;

                        setImageModalOpen(true);

                        setImageModalOrderNo(orderData.orderNo);
                    };

                    return (
                        <Button variant="text" onClick={handleOpenImageModal} sx={{ textTransform: 'capitalize' }}>
                            Show Images
                        </Button>
                    );
                },
            },
            {
                id: 'progressPercent',
                header: 'Progress',
                enableSorting: true,
                enableGlobalFilter: false,
                minWidth: 150,
                cell: ({ row }) => {
                    const orderData = row.original;
                    return <ProgressBar progressPercent={orderData.progressPercent} dueDate={orderData.dueDate} />;
                },
            },
            {
                accessorKey: 'dueDate',
                header: roleSwitch({
                    [ROLES.wholesaler]: 'Target Date',
                    default: 'Deadline',
                }),
                minWidth: 175,
                cell: (info) => {
                    const dateInfo = info.getValue()
                        ? format(parse(info.getValue() as string, SERVER_FORMAT, new Date()), DISPLAY_FORMAT)
                        : '';

                    let isDateAfter = false;

                    if (dateInfo) {
                        isDateAfter = isAfter(new Date(), new Date(info.getValue() as string));
                    }

                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant={'body2'}>{dateInfo}</Typography>
                            {isDateAfter && (
                                <>
                                    <Tooltip
                                        title={roleSwitch({
                                            [ROLES.wholesaler]: `This product is past its target date`,
                                            default: `This product is past its due date`,
                                        })}
                                    >
                                        <Error color={'error'} sx={{ marginLeft: '4px' }} />
                                    </Tooltip>
                                </>
                            )}
                        </Stack>
                    );
                },
                footer: (props) => props.column.id,
            },
        ],
        [processedRows]
    );

    const handleFilter = useCallback((filter) => {
        setSelectedFilter(filter.id);
    }, []);

    const handleSearch = useCallback((text) => {
        setSearchString(text);
    }, []);

    const customTableFilter: FilterFn<OrderData> = useCallback(
        (row, columnId, filterValue, addMeta) => {
            let itemPass = true;

            if (!filterValue) return itemPass;

            const rowColValue: any = row.getValue(columnId);

            if (filterValue.text) {
                // Flip flags if OrderNo or OrderName dont match
                if (!rowColValue.toString().toLowerCase().includes(filterValue.text.toLowerCase())) {
                    itemPass = false;
                }
            }

            if (filterValue.chip && parseInt(filterValue.chip) !== row.original.divCode) {
                itemPass = false;
            }

            return itemPass;
        },
        [processedRows]
    );

    useEffect(() => {
        if (isFetching && !isLoading) {
            enqueueSnackbar('Refetching...', { variant: 'info', key: 'orderSnack' });
        }
    }, [isFetching, isLoading, enqueueSnackbar]);

    const renderSubComponent = useCallback(
        ({ row }: { row: Row<OrderData> }) => {
            return (
                <Stack direction="row">
                    {/* @ts-ignore: Legacy component */}
                    <Details.Tickets row={row.original} currPage={'orderbook'} />
                    <TicketComments ticketList={row.original.tickets} row={row.original} />
                </Stack>
            );
        },
        [processedRows]
    );

    // Loading state component
    if (isLoading) {
        return <SkeletonLoader />;
    }

    return (
        <>
            <TableSearch
                onSearch={handleSearch}
                expansionSlot={
                    [
                        <Stack key={'searchBook'} direction="row" alignItems={'center'} sx={{ mt: 2 }} gap={1}>
                            <Typography>Filters: </Typography>
                            {filterList.map((filterItem) => (
                                <Chip
                                    key={filterItem.value}
                                    variant={selectedFilter === filterItem.id ? 'filled' : 'outlined'}
                                    label={filterItem.value}
                                    color="primary"
                                    size="medium"
                                    onClick={() => handleFilter(filterItem)}
                                />
                            ))}
                        </Stack>,
                    ] as any
                }
            />

            <OTable2<OrderData>
                data={processedRows}
                columns={columnDefs}
                filterData={{
                    text: searchString,
                    chip: selectedFilter,
                }}
                customFilter={customTableFilter}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            />

            {/*  @ts-ignore: Legacy component */}
            <ActionModal.ImageList currPage={'orderBook'} />
        </>
    );
};

export default OrderbookTable;
