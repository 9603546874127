import create from 'zustand';
import { mutator } from 'util/helpers/mutator';

const initialState = {
    selectedOrderNo: '',
    isModalOpen: false,
    isWarranty: false,
};

export default create((set) => ({
    ...initialState,
    setModalToggle: mutator(set, 'isModalOpen'),
    setSelectedOrderNo: mutator(set, 'selectedOrderNo'),
    setIsWarrantyImage: mutator(set, 'isWarranty'),
}));
