import { API } from 'api';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from 'util/constants/api';
import { queryKeys } from 'util/constants/queryKeys';

const options = {
    staleTime: 5000,
    refetchInterval: 10000,
};

const fetchData = async (endpoint, queryParam = '') => {
    const { data } = await API.get(`${API_ENDPOINTS[endpoint].GET}${queryParam}`);

    return data;
};

export const useWarrantyData = () => {
    return useQuery(queryKeys.warrantyData, () => fetchData('WARRANTY'), options);
};
