import { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Box, Button, Chip, Stack, Typography } from '@mui/material'
import { useOrderbook } from 'hooks/pages.hooks';
import { useSnackbar } from 'notistack';
import SkeletonLoader from 'components/SkeletonLoader';
import Error from 'components/ErrorComponent';
import OTable from 'components/Tables/OTable';
import { OrdersRow } from 'components/Tables/Rows';
import useOrderbookStore from 'store/orderbook'
import { usePermission } from 'hooks/permission.hooks';
import { ROLES } from 'util/constants/permissions';
import { PermissionBarrier } from 'components/Permissions';
import useAuthStore from 'store/auth';
import { Replay } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { useQueryClient } from 'react-query';
import { TableSearch } from 'components/Tables/TableSearch';
import { ActionModal } from 'components/Modals';
import OrderbookTable from 'components/OrderbookSections/OrderbookTable';

const filterList = [
	{
		id: '',
		value: 'All'
	},
	{
		id: '2',
		value: 'Clevershade'
	}, 
	{
		id: '1',
		value: 'Revolvashade'
	},
	{
		id: '3',
		value: 'Blacksheep'
	},
	{
		id: '4',
		value: 'Trax'
	},
	{
		id: '5',
		value: 'Misc'
	}
]

function Orderbook() {
	// const { isLoading, isFetching, error, data, isError } = useOrderbook({
	// 	onSuccess: (data) => {
	// 		enqueueSnackbar('Orderbook fetched', {variant: 'success', key: 'orderSnack'})
	// 	},
	// 	onError: (data) => {
	// 		enqueueSnackbar(data.message, {variant: 'error', key: 'orderSnack'})
	// 	}
	// })
	// const { roleSwitch } = usePermission()
	// const authRole = useAuthStore(state => state.role)
	const { enqueueSnackbar } = useSnackbar()
	const queryClient = useQueryClient()
	// const hideOceanicOrders = useOrderbookStore((state) => state.hideOceanicOrders)
	// const setOceanicOrdersHidden = useOrderbookStore((state) => state.setOceanicOrdersHidden )
	const [isForceUpdateLoading, setForceUpdateLoading] = useState(false)
	// const [searchText, setSearchText] = useState('')
	// const [ selectedFilter, setSelectedFilter ] = useState('')


	// const tableColumns = useMemo(() => {
	// 	return [
	// 		{
	// 			id: 'orderNo',
	// 			numeric: 'true',
	// 			label: 'Ord#'
	// 		},
	// 		{
	// 			id: 'customerName',
	// 			numeric: 'false',
	// 			label: 'Customer'
	// 		},
	// 		{
	// 			id: 'clientNo',
	// 			numeric: 'false',
	// 			label: 'Client Ord#'
	// 		},
	// 		// {
	// 		// 	id: 'model',
	// 		// 	numeric: 'false',
	// 		// 	label: 'Model'
	// 		// },
	// 		{
	// 			id: 'jobDescription',
	// 			numeric: 'false',
	// 			label: 'Description'
	// 		},
	// 		// {
	// 		// 	id: 'pc',
	// 		// 	numeric: 'false',
	// 		// 	label: 'PC Progress'
	// 		// },
	// 		// {
	// 		// 	id: 'invNo',
	// 		// 	numeric: 'false',
	// 		// 	label: 'Inv #'
	// 		// },
	// 		{
	// 			id: 'actions',
	// 			numeric: 'false',
	// 			label: 'Actions',
				
	// 		},
	// 		{
	// 			id: 'progress',
	// 			numeric: 'false',
	// 			label: 'Progress'
	// 		},
	// 		{
	// 			id: 'dueDate',
	// 			numeric: 'false',
	// 			label: roleSwitch({
	// 				[ROLES.wholesaler]: 'Target Date',
	// 				default: 'Deadline'
	// 			})
	// 		}
	// 	]
	// }, [roleSwitch])

	// const handleFilter = useCallback((filter) => {
	// 	setSelectedFilter(filter.id)
	// }, [])

	const handleForceUpdate = useCallback(async () => {
		setForceUpdateLoading(true)

		const { data } = await API.get(API_ENDPOINTS.OPTO_UPDATE)

		if (data.success) {
			enqueueSnackbar('Orderbook updated', {variant: 'success'})
			queryClient.invalidateQueries(['orderBook'])
		} else {
			enqueueSnackbar('Failed to update Orderbook', {variant: 'error'})
		}

		setForceUpdateLoading(false)

	}, [enqueueSnackbar, queryClient])

	// useEffect(() => {
	// 	if (isFetching && !isLoading) {
	// 		enqueueSnackbar('Refetching...', {variant: 'info', key: 'orderSnack'})
	// 	}

	// }, [isFetching, isLoading, enqueueSnackbar])

	 // search component is already debounced...
	//  const _handleSearch = useCallback((text) => {
    //     setSearchText(text)
    // }, [])

	return (
		<>
		 	<PermissionBarrier scopes={[ROLES.admin]}>
				<LoadingButton loading={isForceUpdateLoading} variant="contained" sx={{marginTop: '2rem'}}  startIcon={<Replay/>} onClick={handleForceUpdate}>
					Update List
				</LoadingButton>
			</PermissionBarrier>

			<Box sx={{marginTop: 4}}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Current Products
                </Typography>

                {/* <TableSearch  
                    onSearch={_handleSearch}
					expansionSlot={[
						<Stack key={'searchBook'} direction="row" alignItems={'center'} sx={{mt: 2}} gap={1}>
							<Typography>Filters: </Typography>
							{filterList.map((filterItem) => (
								<Chip 
									key={filterItem.value} 
									variant={selectedFilter === filterItem.id ? 'filled' : 'outlined'} 
									label={filterItem.value} 
									color="primary"
									size="medium"
									onClick={() => handleFilter(filterItem)}
								/>
							))}
						</Stack>
					]}
                /> */}

			    {/* {isLoading && <SkeletonLoader />} */}

				{/* {!isLoading && !isError && 
					<OTable 
						rows={data}
						clientInfo={[]}
						employeeInfo={[]}
						commentInfo={[]}
						headCells={tableColumns}
						filterText={searchText}
						extraColSpan={8}
						curPage={"orderBook"}
						renderRow={(props) => {
							if (selectedFilter && (parseInt(selectedFilter) !== props.row.divCode)) {
								return null
							}

							return <OrdersRow {...props}  queryKey={'orderBook'}/>
						}}
					/>
				}	 */}

				<OrderbookTable />
			</Box>			

			{/* <ActionModal.ImageList currPage={'orderBook'} /> */}
		</>
	);
}

export default Orderbook;
