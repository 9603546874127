import { Error, KeyboardArrowDown, KeyboardArrowUp, NotificationImportant } from '@mui/icons-material';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Row } from '@tanstack/react-table';
import OTable2, { CustomColumnDef } from 'components/Tables/OTable2';
import { format, isAfter, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import EnquiryDetails from './EnquiryDetails';
import EnquiryComments from './EnquiryComments';
import { useEnquiryData } from 'hooks/enquiry.hooks';
import { useRetailerList } from 'hooks/lookups.hooks';
import useAuthStore from 'store/auth';
import { hasPermission } from 'components/Permissions';
import { ActionModal } from 'components/Modals';
import { ROLES } from 'util/constants/permissions';
import SkeletonLoader from 'components/SkeletonLoader';
import { EnquiryActions } from './EnquiryActions';
import { useFeedbackModal, useProductModal } from 'store/modals';
import EnquiryProductModal from 'components/Modals/EnquiryProductModal';
import CustomerDetailsModal from 'components/Modals/CustomerDetailsModal';
import { EnquiryData, RawEnquiry, RetailerData } from 'util/types/enquiry-types';

const EnquiryTable = () => {
    const { data, isLoading, error } = useEnquiryData();
    const { data: retailerList, isLoading: isListLoading, error: retailerListError } = useRetailerList();
    const userRole = useAuthStore((state) => state.role);

    const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        customerName: '',
        customerEmail: '',
        customerNumber: '',
    });

    const feedbackModalData = useFeedbackModal((state) => state.modalData);
    const isFeedbackModalOpen = useFeedbackModal((state) => state.isModalOpen);
    const setFeedbackModalOpen = useFeedbackModal((state) => state.setModalOpen);

    const productModalData = useProductModal((state) => state.modalData);
    const isProductModalOpen = useProductModal((state) => state.isModalOpen);
    const setProductModalOpen = useProductModal((state) => state.setModalOpen);

    const roleScope = useMemo(() => [ROLES.admin, ROLES.sales], []);

    const processedData = useMemo<EnquiryData[]>(() => {
        if (!isLoading && !error && !isListLoading && !retailerListError) {
            const rawData = data.message;

            const enqData: EnquiryData[] = rawData.map((item: RawEnquiry) => {
                const retailerData = retailerList.users.find((retailerItem: RetailerData) => retailerItem.id === item.retailer);

                return {
                    enquiry_id: item.id,
                    enquiryDetails: item.information,
                    assigned_wholesaler: retailerData,
                    customerName: item.customer,
                    customerEmail: item.email,
                    customerNumber: item.number,
                    dueDate: item.dueDate,
                    orderNo: item?.orderNo,
                    umbrellas: item?.umbrellas,
                    status: {
                        accepted: item.accepted,
                        contacted: item.contacted,
                        quoted: item.quoted,
                        converted: item.converted,
                        rejected: item.rejected,
                    },
                    comments: item?.comments || [],
                };
            });

            return enqData;
        }

        return [];
    }, [data, isLoading, error, isListLoading, retailerList, retailerListError]);

    const columnDefs = useMemo<CustomColumnDef<EnquiryData>[]>(
        () => [
            {
                id: 'expander',
                header: () => null,
                cell: ({ row }) => {
                    return (
                        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
                            {row.getIsExpanded() ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    );
                },
                isIconButton: true,
                footer: (props) => props.column.id,
            },
            {
                accessorFn: (row) => row.assigned_wholesaler?.username,
                id: 'retailer',
                header: 'Retailer',
                cell: (info) => info.getValue() || '',
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'customerName',
                header: 'Customer Name',
                cell: (info) => info.getValue(),
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'orderNo',
                header: 'Order No.',
                cell: (info) => {
                    const hasAccess = hasPermission({ roleScope, role: userRole });
                    const enqId = info.row.original.enquiry_id.replace('enquiries:', '');

                    if (!info.getValue() && hasAccess && info.row.original.status.converted) {
                        return <ActionModal.AddOrderNo rowId={enqId} isEnquiry />;
                    }

                    return info.getValue() || '';
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                enableSorting: false,
                cell: ({ row }) => {
                    return <EnquiryActions rowData={row.original} />;
                },
            },
            {
                id: 'customerDetails',
                header: 'Customer Details',
                enableSorting: false,
                cell: ({ row }) => {
                    if (!row.original.status.accepted) return <></>;

                    const enqData = row.original;

                    const handleOpenDetails = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();

                        setCustomerDetails({
                            customerName: enqData.customerName,
                            customerEmail: enqData.customerEmail,
                            customerNumber: enqData.customerNumber,
                        });

                        setCustomerModalOpen(true);
                    };

                    return (
                        <Button variant="text" onClick={handleOpenDetails} sx={{ textTransform: 'capitalize' }}>
                            Show Details
                        </Button>
                    );
                },
            },
            {
                accessorKey: 'dueDate',
                header: 'Target Date',
                cell: (info) => {
                    const dateInfo = info.getValue() ? format(parseISO(info.getValue() as string), DISPLAY_FORMAT) : '';

                    let isDateAfter = false;

                    if (dateInfo) {
                        isDateAfter = isAfter(new Date(), new Date(info.getValue() as string));
                    }

                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant={'body2'}>{dateInfo}</Typography>
                            {isDateAfter && (
                                <Tooltip title={'This enquiry is past its target date'}>
                                    <Error color={'error'} sx={{ marginLeft: '4px' }} />
                                </Tooltip>
                            )}
                        </Stack>
                    );
                },
                footer: (props) => props.column.id,
            },
        ],
        [roleScope, userRole]
    );

    const renderSubComponent = ({ row }: { row: Row<EnquiryData> }) => {
        return (
            <Stack direction="row" gap={2} flexWrap={'wrap'}>
                <EnquiryDetails rowData={row} />
                <EnquiryComments enquiryData={row.original} />
            </Stack>
        );
    };

    if (isLoading) {
        return <SkeletonLoader />;
    }

    return (
        <Box>
            <OTable2<EnquiryData>
                data={processedData}
                columns={columnDefs}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            />

            <ActionModal.EnquiryFeedback
                isOpen={isFeedbackModalOpen}
                onClose={() => setFeedbackModalOpen(false)}
                storeData={feedbackModalData}
            />

            <EnquiryProductModal
                isOpen={isProductModalOpen}
                onClose={() => setProductModalOpen(false)}
                modalData={productModalData}
            />

            <CustomerDetailsModal
                isOpen={isCustomerModalOpen}
                onClose={() => setCustomerModalOpen(false)}
                customerData={customerDetails}
            />
        </Box>
    );
};

export default EnquiryTable;
