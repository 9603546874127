import { useState, useMemo, useEffect } from 'react';
import {
    Button,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Grid,
    IconButton,
    Autocomplete,
    Typography,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StyledDialog } from 'components/StyledDialog';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'util/constants/queryKeys';
import { deleteWarranty } from 'util/helpers/apiHelpers';
import { DeleteWarrantyModalType } from 'util/types/warranty-types';

interface DeleteWarrantyModalProps {
    isOpen: boolean;
    onClose: () => void;
    storeData?: DeleteWarrantyModalType;
}

const DeleteWarrantyModal = ({ isOpen, onClose, storeData }: DeleteWarrantyModalProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(false);

    const warrantyId = useMemo(() => {
        if (storeData) {
            return storeData.warrantyId;
        }

        return '';
    }, [storeData]);

    const _onSubmit = async () => {
        setIsLoading(true);

        try {
            let response = await deleteWarranty(warrantyId);

            if (response.success) {
                // run side effect
                queryClient.resetQueries([queryKeys.warrantyData]);
                enqueueSnackbar(`Warranty claim deleted`, { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(`Failed to delete warranty claim`, { variant: 'error' });
            }
        } catch (e) {
            console.log(e, ';; error');
            enqueueSnackbar(`Failed to delete warranty claim`, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <StyledDialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle sx={{ m: 0, pt: 2, alignItems: 'center', display: 'flex', textTransform: 'capitalize' }}>
                {`Reject Claim`}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        marginLeft: 'auto',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ pl: 1 }}>
                    <Typography>Are you sure you want to reject this Warranty Claim?</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton onClick={_onSubmit} loading={isLoading} variant="contained">
                    Reject Claim
                </LoadingButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default DeleteWarrantyModal;
