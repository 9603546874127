import OTable from 'components/Tables/OTable';
import { useDespatch } from 'hooks/pages.hooks';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import SkeletonLoader from 'components/SkeletonLoader';
import Error from 'components/ErrorComponent';
import { DespatchRow } from 'components/Tables/Rows';
import { Box, Button, Typography } from '@mui/material';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { usePermission } from 'hooks/permission.hooks';
import { useMemo } from 'react';
import { ROLES } from 'util/constants/permissions';
import { PermissionBarrier } from 'components/Permissions';
import { TableSearch } from 'components/Tables/TableSearch';
import { ActionModal } from 'components/Modals';
import DespatchTable from 'components/DespatchSections/DespatchTable';

function Despatch() {
    // const { enqueueSnackbar } = useSnackbar()
    // const { roleSwitch } = usePermission()
    // const [searchText, setSearchText] = useState('')
    
    // const { isLoading, error, data, isError, isFetching, refetch } = useDespatch({
	// 	onSuccess: (data) => {
	// 		enqueueSnackbar('Despatch fetched', {variant: 'success', key: 'despatchSnack'})
	// 	},
	// 	onError: (data) => {
	// 		enqueueSnackbar(data.message, {variant: 'error', key: 'despatchSnack'})
	// 	}
	// })



    // const tableColumns = useMemo(() => {
    //     return [
    //         {
    //             id: 'orderNo',
    //             numeric: 'true',
    //             label: 'Ord#'
    //         },
    //         {
    //             id: 'customerName',
    //             numeric: 'false',
    //             label: 'Customer'
    //         },
    //         {
    //             id: 'clientNo',
    //             numeric: 'false',
    //             label: 'Client Ord#'
    //         },
    //         {
    //             id: 'jobDesc',
    //             numeric: 'false',
    //             label: 'Description'
    //         },
    //         {
    //             id: 'paid',
    //             numeric: 'false',
    //             label: 'Paid'
    //         },
    //         {
    //             id: 'actions',
    //             numeric: 'false',
    //             label: 'Actions'
    //         },
    //         {
    //             id: 'dueDate',
    //             numeric: 'false',
    //             label: roleSwitch({
    //                 [ROLES.wholesaler]: 'Target Date',
    //                 default: 'Deadline'
    //             })
    //         }
    //     ]
    // }, [roleSwitch])

	// useEffect(() => {
	// 	if (isFetching && !isLoading) {
	// 		enqueueSnackbar('Refetching...', {variant: 'info', key: 'despatchSnack'})
	// 	}
	// }, [isFetching, isLoading, enqueueSnackbar])

    // // search component is already debounced...
    // const _handleSearch = useCallback((text) => {
    //     setSearchText(text)
    // }, [])


	return (
		<Box sx={{marginTop: 4}}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
               Current Despatch
            </Typography>
            {/* 
            <TableSearch  
                onSearch={_handleSearch}
            /> */}
            
            {/* {isLoading && <SkeletonLoader />}
					
			{!isError && !isLoading && 
				<OTable 
					rows={data}
					headCells={tableColumns}
					curPage={'despatch'}
                    extraColSpan={9}
                    filterText={searchText}
                    sortOrder={'desc'}
					renderRow={(props) => <DespatchRow {...props}/>}
				/> 
			}

            <ActionModal.ImageList currPage={'despatch'} /> */}

            <DespatchTable />
		</Box>
	);
}

export default Despatch;
